import React, { useMemo } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import {
  PricingType,
  BillingAddress,
  ApplySubscription,
  CheckoutStatePlan,
  ApplySubscriptionResults,
} from '@stigg/js-client-sdk';
import { CheckoutContent, CheckoutLayout, CheckoutPanel } from './CheckoutContainer.style';
import { CheckoutProgressBar } from './progressBar/CheckoutProgressBar';
import { CheckoutSummary, CheckoutSummarySkeleton } from './summary';
import { CheckoutStep, CheckoutStepKey, useCheckoutModel, useProgressBarModel } from './hooks';
import { CheckoutAddonsStep } from './steps/addons';
import { PaymentStep } from './steps/payment';
import { useStripeIntegration } from './steps/payment/stripe';
import { CheckoutPlanStep } from './steps/plan';
import { useCheckoutContext } from './CheckoutProvider';
import { ContentLoadingSkeleton } from './components';
import { DowngradeToFreePlan } from './components/DowngradeToFreeContainer';
import { MockCheckoutPreviewCallback } from './types';

type StepProps = {
  content: React.ReactNode;
};

type StripeClientSecret = { clientSecret: string };
type StripeManualMode = { mode: 'setup' | 'payment' | 'subscription'; currency: string };

const getStepProps = (
  currentStep: CheckoutStep,
  {
    onBillingAddressChange,
    onChangePlan,
    collectPhoneNumber,
  }: Pick<CheckoutContainerProps, 'onBillingAddressChange' | 'onChangePlan' | 'collectPhoneNumber'>,
): StepProps => {
  switch (currentStep.key) {
    case CheckoutStepKey.PLAN:
      return { content: <CheckoutPlanStep onChangePlan={onChangePlan} /> };
    case CheckoutStepKey.ADDONS:
      return { content: <CheckoutAddonsStep /> };
    case CheckoutStepKey.PAYMENT:
      return {
        content: (
          <PaymentStep onBillingAddressChange={onBillingAddressChange} collectPhoneNumber={collectPhoneNumber} />
        ),
      };
    default:
      return { content: null };
  }
};

export type CheckoutResult = { success: boolean; errorMessage?: string; results?: ApplySubscriptionResults };

export type OnCheckoutParams = {
  customerId: string;
  checkoutParams: ApplySubscription;
  checkoutAction: (params: ApplySubscription) => Promise<CheckoutResult>;
};

export type OnCheckoutCompletedParams = { success: boolean; error?: string };

export type CheckoutContainerProps = {
  onCheckout?: (params: OnCheckoutParams) => Promise<CheckoutResult>;
  onCheckoutCompleted: (params: OnCheckoutCompletedParams) => Promise<void>;
  onChangePlan?: (params: { currentPlan: CheckoutStatePlan | undefined }) => void;
  onBillingAddressChange?: (params: { billingAddress: BillingAddress }) => Promise<void>;
  disablePromotionCode?: boolean;
  disableSuccessAnimation?: boolean;
  collectPhoneNumber?: boolean;
  onMockCheckoutPreview?: MockCheckoutPreviewCallback;
};

export function CheckoutContainer({
  onCheckout,
  onCheckoutCompleted,
  onChangePlan,
  onBillingAddressChange,
  collectPhoneNumber,
  disablePromotionCode,
  disableSuccessAnimation,
  onMockCheckoutPreview,
}: CheckoutContainerProps) {
  const { stripePromise, setupIntentClientSecret } = useStripeIntegration();
  const [{ stiggTheme, widgetState, theme, isWidgetWatermarkEnabled }] = useCheckoutContext();
  const { currentStep } = useProgressBarModel();

  const { isLoadingCheckoutData } = widgetState;

  const { checkoutState, checkoutLocalization } = useCheckoutModel();
  const { plan, activeSubscription } = checkoutState || {};
  const isFreeDowngrade =
    !!plan &&
    plan.pricingType === PricingType.Free &&
    !!activeSubscription &&
    activeSubscription.pricingType !== PricingType.Free;

  const { content } = getStepProps(currentStep, { onBillingAddressChange, onChangePlan, collectPhoneNumber });

  const checkoutContent = (
    <>
      {isFreeDowngrade ? (
        <DowngradeToFreePlan
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
          freePlan={plan!}
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
          activeSubscription={activeSubscription!}
          allowChangePlan
          checkoutLocalization={checkoutLocalization}
          onChangePlan={onChangePlan}
        />
      ) : (
        <>{content}</>
      )}
    </>
  );

  const stripeElementsMode: StripeClientSecret | StripeManualMode = useMemo(
    () => (setupIntentClientSecret ? { clientSecret: setupIntentClientSecret } : { mode: 'setup', currency: 'usd' }),
    [setupIntentClientSecret],
  );

  return (
    <Elements
      key={setupIntentClientSecret}
      stripe={stripePromise}
      options={{
        ...stripeElementsMode,
        appearance: {
          theme: 'stripe',
          variables: {
            colorText: stiggTheme.palette.text.primary,
            colorPrimaryText: stiggTheme.palette.text.primary,
            colorTextPlaceholder: stiggTheme.palette.text.disabled,
            fontFamily: stiggTheme.typography.fontFamily,
            colorBackground: theme?.paymentInputBackgroundColor,
            borderRadius: theme?.paymentInputBorderRadius,
          },
          rules: theme?.paymentInputBorderColor
            ? {
                '.Input': {
                  borderColor: theme?.paymentInputBorderColor,
                },
              }
            : undefined,
        },
      }}>
      <CheckoutLayout className="stigg-checkout-layout">
        {!isFreeDowngrade && <CheckoutProgressBar />}
        <CheckoutContent>
          <CheckoutPanel>{isLoadingCheckoutData ? <ContentLoadingSkeleton /> : checkoutContent}</CheckoutPanel>
          {isLoadingCheckoutData ? (
            <CheckoutSummarySkeleton isWidgetWatermarkEnabled={isWidgetWatermarkEnabled} />
          ) : (
            <CheckoutSummary
              disablePromotionCode={disablePromotionCode}
              disableSuccessAnimation={disableSuccessAnimation}
              onCheckout={onCheckout}
              onCheckoutCompleted={onCheckoutCompleted}
              isFreeDowngrade={isFreeDowngrade}
              onMockCheckoutPreview={onMockCheckoutPreview}
              isWidgetWatermarkEnabled={isWidgetWatermarkEnabled}
            />
          )}
        </CheckoutContent>
      </CheckoutLayout>
    </Elements>
  );
}
