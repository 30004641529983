import { BillingModel, TiersMode, BillingPeriod, Price, PriceTierFragment, Subscription } from '@stigg/js-client-sdk';
import isNil from 'lodash/isNil';
import { sum } from 'lodash';
import { PaywallPlan } from '../paywall';
import { CurrentSubscriptionOverride, SelectDefaultTierIndexFn } from '../paywall/types';

export function getPriceFeatureUnit(price: Price) {
  if (!price.feature) {
    return '';
  }

  return (price.feature.unitQuantity !== 1 ? price.feature.unitsPlural : price.feature.units) || '';
}

export function getTierByQuantity(tiers: PriceTierFragment[], quantity: number): PriceTierFragment {
  const ascendingTiers = [...tiers];
  // Sort tiers by upTo value, ascending, if upTo is not set,
  // put it at the end as it represent the last infinity tier
  ascendingTiers.sort((a, b) => (!a.upTo ? 1 : !b.upTo ? -1 : a.upTo - b.upTo));

  for (const tier of ascendingTiers) {
    if (tier.upTo && quantity <= tier.upTo) {
      return tier;
    }
  }

  return ascendingTiers[ascendingTiers.length - 1];
}

function getAmount(amount: number, selectedBillingPeriod?: BillingPeriod, shouldShowMonthlyPriceAmount?: boolean) {
  return selectedBillingPeriod === BillingPeriod.Annually && shouldShowMonthlyPriceAmount ? amount / 12 : amount;
}

function calculateTierPriceVolume(
  currentTier: PriceTierFragment,
  perUnitQuantity: number,
  selectedBillingPeriod?: BillingPeriod,
  shouldShowMonthlyPriceAmount?: boolean,
): number {
  let amount = 0;

  if (currentTier.unitPrice) {
    const unitPrice = getAmount(currentTier.unitPrice.amount, selectedBillingPeriod, shouldShowMonthlyPriceAmount);
    amount += unitPrice * perUnitQuantity;
  }

  if (currentTier.flatPrice) {
    amount += getAmount(currentTier.flatPrice.amount, selectedBillingPeriod, shouldShowMonthlyPriceAmount);
  }

  return amount;
}

export function calculateTierPriceGraduated(
  tiers: PriceTierFragment[],
  unitQuantity: number,
  selectedBillingPeriod?: BillingPeriod,
  shouldShowMonthlyPriceAmount?: boolean,
): { total: number; breakdown: Array<{ unitQuantity: number; amount: number }> } {
  let remainingQuantity = unitQuantity;
  let prevUpTo = 0;
  let currentTierIndex = 0;

  const breakdown: Array<{ unitQuantity: number; amount: number }> = [];

  while (remainingQuantity > 0 && currentTierIndex < tiers.length) {
    const tier = tiers[currentTierIndex];
    const { upTo } = tier;

    if (isNil(upTo)) {
      breakdown.push({
        unitQuantity: remainingQuantity,
        amount: calculateTierPriceVolume(tier, remainingQuantity, selectedBillingPeriod, shouldShowMonthlyPriceAmount),
      });
      remainingQuantity = 0;
    } else {
      const unitsInTier = upTo - prevUpTo;
      const consumed = Math.min(remainingQuantity, unitsInTier);
      breakdown.push({
        unitQuantity: consumed,
        amount: calculateTierPriceVolume(tier, consumed, selectedBillingPeriod, shouldShowMonthlyPriceAmount),
      });
      remainingQuantity -= consumed;
      prevUpTo = upTo;
    }

    currentTierIndex += 1;
  }

  const total = sum(breakdown.map(({ amount }) => amount));

  return {
    breakdown,
    total,
  };
}

export function calculateTierPrice(
  price: Pick<Price, 'tiers' | 'tiersMode'>,
  unitQuantity: number,
  selectedBillingPeriod?: BillingPeriod,
  shouldShowMonthlyPriceAmount?: boolean,
): number {
  if (!price.tiers) {
    return 0;
  }

  switch (price.tiersMode) {
    case TiersMode.Volume: {
      const currentTier = getTierByQuantity(price.tiers, unitQuantity);
      return calculateTierPriceVolume(currentTier, unitQuantity, selectedBillingPeriod, shouldShowMonthlyPriceAmount);
    }
    case TiersMode.Graduated: {
      return calculateTierPriceGraduated(price.tiers, unitQuantity, selectedBillingPeriod, shouldShowMonthlyPriceAmount)
        .total;
    }
    default:
      return 0;
  }
}

export function hasTierWithUnitPrice(tiers: PriceTierFragment[] | null | undefined) {
  return tiers?.some(({ unitPrice, upTo }) => unitPrice && !isNil(upTo));
}

export function isBulkTiers(tiers: PriceTierFragment[] | null | undefined) {
  return tiers?.every(({ unitPrice, upTo }) => !unitPrice || isNil(upTo));
}

export function isQuantityInFirstTier(tiers: PriceTierFragment[] | null | undefined, quantity: number) {
  return tiers?.[0].upTo && quantity <= tiers[0].upTo;
}

export function getTiersPerUnitQuantities({
  plan,
  billingPeriod,
  currentSubscription,
  currentSubscriptionOverride,
  selectDefaultTierIndex,
}: {
  plan: PaywallPlan;
  billingPeriod: BillingPeriod;
  currentSubscription: Subscription | null;
  currentSubscriptionOverride?: CurrentSubscriptionOverride | null;
  selectDefaultTierIndex?: SelectDefaultTierIndexFn;
}): Record<string, number> {
  const planTierPrices = plan.pricePoints.filter(
    (price) => price.billingPeriod === billingPeriod && price.isTieredPrice,
  );

  if (planTierPrices.length === 1) {
    const [price] = planTierPrices;
    const { featureId } = price.feature!;
    const selectedDefaultTierIndex = selectDefaultTierIndex ? selectDefaultTierIndex({ plan }) : 0;
    const currentTier = price.tiers![selectedDefaultTierIndex];
    let quantity = hasTierWithUnitPrice(price.tiers) ? 1 : currentTier.upTo || 1;

    if (currentSubscription && currentSubscription.plan.id === plan.id) {
      const tieredPrice = currentSubscription.prices.find(
        (subscriptionPrice) =>
          subscriptionPrice.pricingModel === BillingModel.PerUnit &&
          subscriptionPrice.tiersMode &&
          subscriptionPrice.feature?.featureId === featureId,
      );

      if (tieredPrice) {
        quantity = tieredPrice.feature!.unitQuantity || 1;
      }
    }

    if (currentSubscriptionOverride?.planId === plan.id) {
      const billableFeature = currentSubscriptionOverride.billableFeatures.find(
        (billableFeature) => billableFeature.featureId === featureId,
      );
      if (billableFeature) {
        quantity = billableFeature.quantity;
      }
    }

    const result: Record<string, number> = {};
    result[featureId] = quantity;

    return result;
  }

  return {};
}

export enum PriceTierComparison {
  Lower = -1,
  Equal = 0,
  Higher = 1,
}

export function compareSelectedTierToCurrentTier({
  perUnitQuantityByFeature,
  plan,
  currentSubscription,
  currentSubscriptionOverride,
}: {
  perUnitQuantityByFeature: Record<string, number>;
  plan: PaywallPlan;
  currentSubscription: Subscription | null;
  currentSubscriptionOverride?: CurrentSubscriptionOverride | null;
}): PriceTierComparison {
  if (!currentSubscription) {
    return PriceTierComparison.Equal;
  }

  let currentTierPrice = currentSubscription.prices.find(
    (price) => price.pricingModel === BillingModel.PerUnit && price.tiersMode,
  );

  const isCurrentPlanOverride = plan.id === currentSubscriptionOverride?.planId;
  if (isCurrentPlanOverride) {
    currentTierPrice =
      plan.pricePoints.find((price) => price.pricingModel === BillingModel.PerUnit && price.tiersMode) ??
      currentTierPrice;
  }

  if (!currentTierPrice) {
    return PriceTierComparison.Equal;
  }

  const { featureId, unitQuantity } = currentTierPrice.feature!;
  let oldQuantity = unitQuantity;

  if (isCurrentPlanOverride) {
    const billableFeature = currentSubscriptionOverride?.billableFeatures?.find(
      (billableFeature) => billableFeature.featureId === featureId,
    );
    if (billableFeature) {
      oldQuantity = billableFeature.quantity;
    }
  }

  if (isNil(oldQuantity)) {
    return PriceTierComparison.Equal;
  }

  const newQuantity = perUnitQuantityByFeature[featureId];

  if (newQuantity < oldQuantity) {
    return PriceTierComparison.Lower;
  }
  if (newQuantity > oldQuantity) {
    return PriceTierComparison.Higher;
  }
  return PriceTierComparison.Equal;
}
