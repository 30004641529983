import React, { useState } from 'react';
import partition from 'lodash/partition';
import styled from '@emotion/styled/macro';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { BillingModel } from '@stigg/js-client-sdk';
import { PoweredByStigg } from '../../common/PoweredByStigg';
import { Typography } from '../../common/Typography';
import { useChargesSort } from '../../hooks/useChargeSort';
import { currencyPriceFormatter } from '../../utils/currencyUtils';
import { Button } from '../components';
import {
  useCheckoutModel,
  useProgressBarModel,
  usePreviewSubscription,
  useSubscriptionModel,
  usePaymentStepModel,
} from '../hooks';
import { PromotionCodeSection } from '../promotionCode';
import { useSubmit } from '../steps/payment/stripe';
import { CheckoutContainerProps } from '../CheckoutContainer';
import { CheckoutCaptions } from './components/CheckoutCaptions';
import {
  AppliedCreditsLineItem,
  BilledPriceLineItem,
  DiscountLineItem,
  FreeChargeLineItem,
  LineItemContainer,
  LineItemRow,
  TaxLineItem,
} from './components/LineItems';
import { WithSkeleton } from './components/WithSkeleton';
import { Icon } from '../../common/Icon';
import { CheckoutLocalization } from '../configurations/textOverrides';
import { CheckoutSuccess } from './CheckoutSuccess';
import { getFeatureDisplayNameText } from '../../utils/getFeatureName';
import { mq } from '../../common/mediaQuery';

export const SummaryContainer = styled(Box)`
  width: 100%;
  max-width: 470px;
  flex: 1.5;
`;

export const SummaryCard = styled(Paper)`
  background: ${({ theme }) => theme.stigg.palette.backgroundHighlight};
  padding: 16px;

  ${mq.md} {
    border-radius: 10px;
  }
`;

SummaryCard.defaultProps = {
  elevation: 0,
};

const SummaryTitle = styled(Typography)`
  margin-bottom: 16px;
  font-weight: 500;
`;

const StyledDivider = styled(Divider)`
  margin: 16px 0;
`;

const TotalDueText = styled(Typography)`
  margin-bottom: 8px;
`;

function resolveCheckoutButtonText({
  isLastStep,
  checkoutHasChanges,
  isFreeDowngrade,
  checkoutLocalization,
  isPlanUpdate,
}: {
  isLastStep?: boolean;
  checkoutHasChanges: boolean;
  isFreeDowngrade: boolean;
  checkoutLocalization: CheckoutLocalization;
  isPlanUpdate?: boolean;
}) {
  if (!isLastStep) {
    return checkoutLocalization.checkoutButton.nextText;
  }

  if (!checkoutHasChanges) {
    return checkoutLocalization.checkoutButton.noChangesText;
  }

  if (isPlanUpdate) {
    return checkoutLocalization.checkoutButton.updateText;
  }

  if (isFreeDowngrade) {
    return checkoutLocalization.checkoutButton.downgradeToFreeText;
  }

  return checkoutLocalization.checkoutButton.upgradeText;
}

export const CheckoutSummary = ({
  onCheckout,
  onCheckoutCompleted,
  disablePromotionCode,
  disableSuccessAnimation,
  isFreeDowngrade,
  onMockCheckoutPreview,
  isWidgetWatermarkEnabled,
}: CheckoutContainerProps & { isFreeDowngrade: boolean; isWidgetWatermarkEnabled: boolean }) => {
  const [isCheckoutCompletedSuccessfully, setIsCheckoutCompletedSuccessfully] = useState(false);
  const { setErrorMessage } = usePaymentStepModel();
  const progressBar = useProgressBarModel();
  const subscription = useSubscriptionModel();
  const { checkoutState, checkoutLocalization } = useCheckoutModel();
  const { plan, activeSubscription } = checkoutState || {};
  const planPrices = useChargesSort(
    plan?.pricePoints?.filter((price) => price.billingPeriod === subscription.billingPeriod) || [],
  );
  const [baseCharges, usageCharges] = partition(planPrices, (price) => price.pricingModel === BillingModel.FlatFee);
  const [baseCharge] = baseCharges || [];
  const isLastStep = isFreeDowngrade || (progressBar.isCheckoutComplete && progressBar.isLastStep);

  const { subscriptionPreview, isFetchingSubscriptionPreview } = usePreviewSubscription({ onMockCheckoutPreview });

  const { handleSubmit, isLoading } = useSubmit({
    isMocked: !!onMockCheckoutPreview, // This is a hack to make the submit button work with mocked data
    disableSuccessAnimation,
    onCheckout,
    onCheckoutCompleted,
    onSuccess: () => {
      setIsCheckoutCompletedSuccessfully(true);
      progressBar.markStepAsCompleted(progressBar.progressBarState.activeStep);
    },
  });

  const handleCheckout = async (e: any) => {
    if (isCheckoutCompletedSuccessfully) {
      return;
    }

    const { errorMessage } = (await handleSubmit(e)) || {};
    if (errorMessage) {
      setErrorMessage(errorMessage);
      setIsCheckoutCompletedSuccessfully(false);
    } else {
      setErrorMessage(undefined);
    }
  };

  const onCheckoutClick = async (e: any): Promise<void> => {
    if (isLoading) {
      return;
    }

    if (isLastStep) {
      await handleCheckout(e);
    } else {
      progressBar.goNext();
    }
  };

  const { immediateInvoice, recurringInvoice } = subscriptionPreview || {};
  const checkoutHasChanges =
    !!subscriptionPreview && (!!immediateInvoice?.proration || !!subscriptionPreview.hasScheduledUpdates);
  const showPromotionCodeLine = !disablePromotionCode && !isFreeDowngrade;
  const showDiscountLine = !!recurringInvoice?.discountDetails && !isFreeDowngrade;
  const hasDiscounts = showPromotionCodeLine || showDiscountLine;

  const hasPayAsYouGoCharges = usageCharges.some((price) => price.pricingModel === BillingModel.UsageBased);
  const onlyPayAsYouGoCharges =
    hasPayAsYouGoCharges &&
    !baseCharge &&
    usageCharges.every((price) => price.pricingModel === BillingModel.UsageBased);

  const baseChargeLabel =
    typeof checkoutLocalization.summary.baseChargeText === 'function'
      ? checkoutLocalization.summary.baseChargeText({ billingPeriod: subscription.billingPeriod })
      : checkoutLocalization.summary.baseChargeText;

  return (
    <SummaryContainer>
      <SummaryCard>
        <SummaryTitle variant="h3">{checkoutLocalization.summary.title}</SummaryTitle>

        <Grid
          className="stigg-checkout-summary-plan-title-container"
          display="flex"
          flexDirection="row"
          alignItems="center"
          marginY={2}>
          <Typography variant="h6" color="primary" style={{ paddingRight: '8px' }}>
            {checkoutLocalization.summary.planName({ plan: plan! })}
          </Typography>
          <StyledDivider className="stigg-checkout-summary-divider" sx={{ flex: 1, margin: '0 !important' }} />
        </Grid>

        {baseCharge && (
          <BilledPriceLineItem
            checkoutLocalization={checkoutLocalization}
            label={baseChargeLabel}
            quantity={1}
            price={baseCharge}
          />
        )}
        {!baseCharge && isFreeDowngrade ? <FreeChargeLineItem label={baseChargeLabel} /> : null}

        <Grid item className="stigg-checkout-summary-usage-charges-container">
          {usageCharges.map((price) => {
            const priceBillableFeature = subscription.billableFeatures?.find(
              (billableFeature) => billableFeature.featureId === price.feature?.featureId,
            );

            return (
              <BilledPriceLineItem
                checkoutLocalization={checkoutLocalization}
                key={price.feature?.featureId}
                label={getFeatureDisplayNameText(
                  price.feature?.displayName || '',
                  price.feature?.units,
                  price.feature?.unitsPlural,
                )}
                quantity={priceBillableFeature?.quantity || 1}
                price={price}
              />
            );
          })}
        </Grid>

        {!!subscription.addons?.length && (
          <>
            <Grid display="flex" flexDirection="row" alignItems="center" marginY={2}>
              <Typography variant="h6" color="primary" style={{ paddingRight: '8px' }}>
                {checkoutLocalization.summary.addonsSectionTitle}
              </Typography>
              <StyledDivider className="stigg-checkout-summary-divider" sx={{ flex: 1, margin: '0 !important' }} />
            </Grid>
            {subscription.addons.map((addon) => {
              const addonPrice = addon.addon.pricePoints?.find(
                (price) => price.billingPeriod === subscription.billingPeriod,
              );

              if (!addonPrice) return null;

              const addonQuantity = addon.quantity && addon.quantity > 0 ? addon.quantity : 1;

              return (
                <BilledPriceLineItem
                  checkoutLocalization={checkoutLocalization}
                  key={addon?.addon?.id}
                  label={addon.addon.displayName}
                  quantity={addonQuantity}
                  price={addonPrice}
                />
              );
            })}
          </>
        )}

        {!hasDiscounts && <StyledDivider className="stigg-checkout-summary-divider" />}
        {hasDiscounts && (
          <Grid display="flex" flexDirection="row" alignItems="center" marginY={2}>
            <Typography variant="h6" color="primary" style={{ paddingRight: '8px' }}>
              {checkoutLocalization.summary.discountsSectionTitle}
            </Typography>
            <StyledDivider className="stigg-checkout-summary-divider" sx={{ flex: 1, margin: '0 !important' }} />
          </Grid>
        )}

        {showPromotionCodeLine && (
          <PromotionCodeSection
            disabled={isLoading || isFetchingSubscriptionPreview}
            checkoutLocalization={checkoutLocalization}
            onMockCheckoutPreview={onMockCheckoutPreview}
          />
        )}

        {showDiscountLine && (
          <DiscountLineItem
            subscriptionPreview={subscriptionPreview!}
            isFetchingSubscriptionPreview={isFetchingSubscriptionPreview}
            checkoutLocalization={checkoutLocalization}
          />
        )}

        {hasDiscounts && <StyledDivider className="stigg-checkout-summary-divider" />}

        <TaxLineItem
          tax={recurringInvoice?.tax}
          taxDetails={recurringInvoice?.taxDetails}
          isFetchingSubscriptionPreview={isFetchingSubscriptionPreview}
          checkoutLocalization={checkoutLocalization}
        />

        {!isFreeDowngrade ? (
          <>
            <LineItemRow style={{ marginTop: 16 }}>
              <Grid display="flex" flexDirection="column" container>
                <Grid item display="flex" justifyContent="space-between">
                  <Typography variant="h6">{checkoutLocalization.summary.totalText}</Typography>
                  <Typography variant="h6">
                    <WithSkeleton isLoading={isFetchingSubscriptionPreview}>
                      {onlyPayAsYouGoCharges ? checkoutLocalization.summary.onlyPayAsYouGoText : null}
                      {!onlyPayAsYouGoCharges && hasPayAsYouGoCharges
                        ? checkoutLocalization.summary.startsAtText
                        : null}
                      {!onlyPayAsYouGoCharges
                        ? currencyPriceFormatter({
                            amount: 0,
                            ...recurringInvoice?.total,
                            minimumFractionDigits: 2,
                          })
                        : null}
                    </WithSkeleton>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" color="secondary">
                    {checkoutLocalization.summary.totalBillingPeriodText({ billingPeriod: subscription.billingPeriod })}
                  </Typography>
                </Grid>
              </Grid>
            </LineItemRow>
            <StyledDivider className="stigg-checkout-summary-divider" />
          </>
        ) : null}

        {immediateInvoice?.proration?.hasProrations &&
        immediateInvoice?.subTotal &&
        immediateInvoice?.subTotal.amount > 0 ? (
          <LineItemContainer>
            <LineItemRow>
              <Typography variant="body1" color="secondary">
                {checkoutLocalization.summary.proratedTotalDueText}
              </Typography>
              <Typography variant="body1" color="secondary">
                <WithSkeleton isLoading={isFetchingSubscriptionPreview}>
                  {currencyPriceFormatter({
                    amount: immediateInvoice?.subTotal?.amount + (immediateInvoice?.tax?.amount || 0),
                    currency: immediateInvoice?.subTotal.currency,
                    minimumFractionDigits: 2,
                  })}
                </WithSkeleton>
              </Typography>
            </LineItemRow>
          </LineItemContainer>
        ) : null}

        <AppliedCreditsLineItem
          subscriptionPreview={subscriptionPreview}
          isFetchingSubscriptionPreview={isFetchingSubscriptionPreview}
          checkoutLocalization={checkoutLocalization}
        />

        <LineItemContainer>
          <LineItemRow>
            <TotalDueText variant="h6">{checkoutLocalization.summary.totalDueText}</TotalDueText>
            <TotalDueText variant="h6">
              <WithSkeleton isLoading={isFetchingSubscriptionPreview}>
                {currencyPriceFormatter({ amount: 0, ...immediateInvoice?.total, minimumFractionDigits: 2 })}
              </WithSkeleton>
            </TotalDueText>
          </LineItemRow>
        </LineItemContainer>

        <CheckoutCaptions
          plan={plan}
          activeSubscription={activeSubscription}
          subscriptionPreview={subscriptionPreview}
          isFetchingSubscriptionPreview={isFetchingSubscriptionPreview}
          checkoutLocalization={checkoutLocalization}
          billingPeriod={subscription.billingPeriod}
        />

        <Button
          $success={isCheckoutCompletedSuccessfully}
          $error={isLastStep && isFreeDowngrade}
          disabled={
            isLoading ||
            isFetchingSubscriptionPreview ||
            progressBar.progressBarState.isDisabled ||
            (isLastStep && !checkoutHasChanges)
          }
          className="stigg-checkout-summary-cta-button"
          sx={{ textTransform: 'none', borderRadius: '10px', marginTop: '24px', height: '36px' }}
          variant="contained"
          size="medium"
          onClick={(e: any) => {
            void onCheckoutClick(e);
          }}
          fullWidth>
          <Typography
            className="stigg-checkout-summary-cta-button-text"
            variant="h3"
            color="white"
            style={{ display: 'flex' }}>
            {isCheckoutCompletedSuccessfully ? (
              <Icon icon="Check" style={{ display: 'contents' }} />
            ) : isLoading || isFetchingSubscriptionPreview ? (
              <CircularProgress size={20} sx={{ color: 'white' }} />
            ) : (
              resolveCheckoutButtonText({
                isLastStep,
                checkoutHasChanges,
                isFreeDowngrade,
                checkoutLocalization,
                isPlanUpdate: !!activeSubscription && activeSubscription?.plan.id === plan?.id,
              })
            )}
          </Typography>
        </Button>
      </SummaryCard>
      <PoweredByStigg
        source="checkout"
        showWatermark={isWidgetWatermarkEnabled}
        style={{ marginTop: 8, display: 'flex', justifyContent: 'center' }}
      />
      {!disableSuccessAnimation && isCheckoutCompletedSuccessfully && (
        <CheckoutSuccess checkoutLocalization={checkoutLocalization} />
      )}
    </SummaryContainer>
  );
};
