import { BillableFeature, BillingPeriod, Customer, Plan, PricingType, Subscription } from '@stigg/js-client-sdk';
import React, { useCallback, useMemo } from 'react';
import styled from '@emotion/styled/macro';
import { PlanOffering } from './PlanOffering';
import { BillingPeriodPicker } from './BillingPeriodPicker';
import { calculatePaywallDiscountRate } from '../utils/calculateDiscountRate';
import {
  ShouldHidePlanFn,
  OnPlanSelectedCallbackFn,
  PaywallPlan,
  SubscribeIntentionType,
  SelectDefaultTierIndexFn,
  CurrentSubscriptionOverride,
} from './types';
import { PaywallLocalization } from './paywallTextOverrides';
import { PoweredByStigg } from '../common/PoweredByStigg';
import { useStiggContext } from '../..';
import { hasPricePointsForPlans } from './utils/hasPricePoints';
import { getPlansToDisplay } from './utils/getPlansToDisplay';
import { getPlanPrice } from '../utils/getPlanPrice';
import { getTiersPerUnitQuantities } from '../utils/priceTierUtils';

const PaywallPlansContainer = styled.div`
  color: ${({ theme }) => theme.stigg.palette.text.primary};
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px 0;
  gap: ${({ theme }) => theme.stigg.layout.planMargin};
`;

const PaywallContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & * {
    box-sizing: border-box;
  }
`;

const PaywallLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

type PaywallProps = {
  plans: PaywallPlan[];
  customer: Customer | null;
  currentSubscription: Subscription | null;
  currentSubscriptionOverride?: CurrentSubscriptionOverride | null;
  selectedBillingPeriod: BillingPeriod;
  highlightedPlanId?: string;
  onBillingPeriodChanged: (billingPeriod: BillingPeriod) => void;
  availableBillingPeriods: BillingPeriod[];
  isCustomerOnTrial: boolean;
  onPlanSelected: OnPlanSelectedCallbackFn;
  paywallLocale: PaywallLocalization;
  locale: string;
  shouldHidePlan?: ShouldHidePlanFn;
  selectDefaultTierIndex?: SelectDefaultTierIndexFn;
};

export const Paywall = ({
  plans,
  customer,
  currentSubscription,
  highlightedPlanId,
  selectedBillingPeriod,
  onBillingPeriodChanged,
  availableBillingPeriods,
  isCustomerOnTrial,
  onPlanSelected,
  paywallLocale,
  locale,
  shouldHidePlan,
  selectDefaultTierIndex,
  currentSubscriptionOverride,
}: PaywallProps) => {
  const { stigg } = useStiggContext();
  const discountRate = calculatePaywallDiscountRate(plans);
  const shouldShowDescriptionSection = plans.some((plan) => !!plan.description);
  const hasMonthlyPrice = hasPricePointsForPlans(plans, BillingPeriod.Monthly);
  const hasAnnuallyPrice = hasPricePointsForPlans(plans, BillingPeriod.Annually);
  const plansToShow = getPlansToDisplay(plans, selectedBillingPeriod, shouldHidePlan);

  const handleOnSubscribe = useCallback(
    (plan: Plan, intentionType: SubscribeIntentionType, billableFeatures: BillableFeature[]) => {
      return onPlanSelected({
        plan,
        customer,
        subscription: currentSubscription,
        intentionType,
        selectedBillingPeriod,
        billableFeatures,
      });
    },
    [customer, selectedBillingPeriod, currentSubscription, onPlanSelected],
  );

  const isCustomerInCustomPlan = !!currentSubscription && currentSubscription.plan.pricingType === PricingType.Custom;

  const withStartingAtRow = useMemo(
    () =>
      plansToShow.some((plan) => {
        const planPrices = plan.pricePoints.filter((pricePoint) => pricePoint.billingPeriod === selectedBillingPeriod);
        const paywallCalculatedPrice = plan.paywallCalculatedPricePoints?.find(
          (pricePoint) => pricePoint.billingPeriod === selectedBillingPeriod,
        );
        return planPrices.length > 1 && !!paywallCalculatedPrice?.additionalChargesMayApply;
      }),
    [selectedBillingPeriod, plansToShow],
  );

  const withUnitPriceRow = useMemo(
    () =>
      plansToShow.some((plan) => {
        return !!getPlanPrice(plan, selectedBillingPeriod, paywallLocale, locale, hasMonthlyPrice).unit;
      }),
    [selectedBillingPeriod, hasMonthlyPrice, locale, paywallLocale, plansToShow],
  );

  const withTiersRow = useMemo(() => {
    return (
      !isCustomerInCustomPlan &&
      plansToShow.some((plan) => {
        const tiers = getTiersPerUnitQuantities({
          plan,
          billingPeriod: selectedBillingPeriod,
          currentSubscription,
          currentSubscriptionOverride,
        });
        return Object.values(tiers).length > 0;
      })
    );
  }, [selectedBillingPeriod, currentSubscription, currentSubscriptionOverride, isCustomerInCustomPlan, plansToShow]);

  const withTrialLeftRow = plansToShow.some((plan) => {
    return plan.isCurrentCustomerPlan && plan.trialDaysLeft;
  });

  return (
    <PaywallContainer className="stigg-paywall-container">
      <PaywallLayout className="stigg-paywall-layout">
        <BillingPeriodPicker
          onBillingPeriodChanged={onBillingPeriodChanged}
          selectedBillingPeriod={selectedBillingPeriod}
          availableBillingPeriods={availableBillingPeriods}
          discountRate={discountRate}
        />

        <PaywallPlansContainer className="stigg-paywall-plans-layout">
          {plansToShow.map((plan) => (
            <PlanOffering
              withUnitPriceRow={withUnitPriceRow}
              withTiersRow={withTiersRow}
              withTrialLeftRow={withTrialLeftRow}
              key={plan.id}
              shouldShowDescriptionSection={shouldShowDescriptionSection}
              hasMonthlyPrice={hasMonthlyPrice}
              hasAnnuallyPrice={hasAnnuallyPrice}
              plan={plan}
              withStartingAtRow={withStartingAtRow}
              currentSubscription={currentSubscription}
              currentSubscriptionOverride={currentSubscriptionOverride}
              billingPeriod={selectedBillingPeriod}
              isHighlighted={plan.id === highlightedPlanId}
              isCustomerOnTrial={isCustomerOnTrial}
              onPlanSelected={(intentionType: SubscribeIntentionType, billableFeatures: BillableFeature[]) =>
                handleOnSubscribe(plan, intentionType, billableFeatures)
              }
              paywallLocale={paywallLocale}
              locale={locale}
              customer={customer}
              isCustomerInCustomPlan={isCustomerInCustomPlan}
              selectDefaultTierIndex={selectDefaultTierIndex}
            />
          ))}
        </PaywallPlansContainer>
        <PoweredByStigg
          source="paywall"
          align="end"
          style={{ marginTop: 16 }}
          showWatermark={stigg.isWidgetWatermarkEnabled}
        />
      </PaywallLayout>
    </PaywallContainer>
  );
};
