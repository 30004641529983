/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import moment from 'moment';
import { Box } from '@mui/material';
import { BillingModel, BillingPeriod, Plan, Subscription, SubscriptionPreviewV2 } from '@stigg/js-client-sdk';
import { Typography } from '../../../common/Typography';
import { currencyPriceFormatter } from '../../../utils/currencyUtils';
import { CheckoutLocalization } from '../../configurations/textOverrides';
import { WithSkeleton } from './WithSkeleton';

export type CheckoutCaptionProps = {
  subscriptionPreview?: SubscriptionPreviewV2 | null;
  isFetchingSubscriptionPreview: boolean;
  activeSubscription?: Subscription | null;
  plan?: Plan;
  checkoutLocalization: CheckoutLocalization;
  billingPeriod: BillingPeriod;
};

const RemainingCreditsCaption = ({
  subscriptionPreview,
  isFetchingSubscriptionPreview,
  checkoutLocalization,
}: CheckoutCaptionProps) => {
  const { immediateInvoice } = subscriptionPreview || {};
  if (!immediateInvoice?.proration?.netAmount?.amount || immediateInvoice?.proration?.netAmount?.amount >= 0) {
    return null;
  }

  const positiveAmount = immediateInvoice.proration.netAmount.amount * -1;

  const credits = currencyPriceFormatter({
    amount: positiveAmount,
    currency: immediateInvoice.proration.netAmount.currency,
    minimumFractionDigits: 2,
  });

  return (
    <Typography variant="body1" style={{ marginTop: 14 }} className="stigg-checkout-summary-captions-remaining-credits">
      <WithSkeleton isLoading={isFetchingSubscriptionPreview} width="100%">
        {checkoutLocalization.summary.creditsForUnusedTimeText({ credits })}
      </WithSkeleton>
    </Typography>
  );
};

const ScheduledUpdatesCaption = ({
  subscriptionPreview,
  activeSubscription,
  isFetchingSubscriptionPreview,
  checkoutLocalization,
}: CheckoutCaptionProps) => {
  if (!subscriptionPreview?.hasScheduledUpdates || !activeSubscription?.currentBillingPeriodEnd) {
    return null;
  }

  const { currentBillingPeriodEnd } = activeSubscription;
  const changesWillApplyText =
    typeof checkoutLocalization.summary.changesWillApplyAtEndOfBillingPeriod === 'function'
      ? checkoutLocalization.summary.changesWillApplyAtEndOfBillingPeriod({ billingPeriodEnd: currentBillingPeriodEnd })
      : checkoutLocalization.summary.changesWillApplyAtEndOfBillingPeriod;

  return (
    <Typography variant="body1" style={{ marginTop: 14 }} className="stigg-checkout-summary-captions-scheduled-updates">
      <WithSkeleton isLoading={isFetchingSubscriptionPreview} width="100%">
        {changesWillApplyText}
      </WithSkeleton>
    </Typography>
  );
};

const NextBillingCaption = ({
  subscriptionPreview,
  activeSubscription,
  plan,
  isFetchingSubscriptionPreview,
  billingPeriod,
}: CheckoutCaptionProps) => {
  const { recurringInvoice } = subscriptionPreview || {};
  if (!subscriptionPreview || !recurringInvoice?.total.amount) {
    return null;
  }

  const currentBillingPeriodEnd = subscriptionPreview.hasScheduledUpdates
    ? activeSubscription?.currentBillingPeriodEnd
    : subscriptionPreview?.billingPeriodRange.end;

  const total = currencyPriceFormatter({
    ...recurringInvoice.total,
    minimumFractionDigits: 2,
  });

  const hasUnitBasedPricing = plan?.pricePoints.some((price) => price.pricingModel === BillingModel.UsageBased);
  const hasNonUnitBasedPricing = plan?.pricePoints.some((price) => price.pricingModel !== BillingModel.UsageBased);

  let text = 'We will bill you ';
  let totalAmountText = `${total} `;

  if (hasUnitBasedPricing) {
    if (!hasNonUnitBasedPricing) {
      totalAmountText = 'for';
    } else {
      totalAmountText += '+';
    }

    totalAmountText += ' applicable usage-based charges for this subscription ';
  }

  let nextBillingDate;
  let billingPeriodText;

  switch (billingPeriod) {
    case BillingPeriod.Monthly:
      billingPeriodText = 'month';
      nextBillingDate = `the ${moment(currentBillingPeriodEnd).format('Do')}`;
      break;
    case BillingPeriod.Annually:
      billingPeriodText = 'year';
      nextBillingDate = moment(currentBillingPeriodEnd).format('MMMM Do');
      break;
    default: {
      break;
    }
  }

  text += `${totalAmountText}for this subscription every ${billingPeriodText} on ${nextBillingDate}, unless you cancel.`;

  return (
    <Typography variant="body1" style={{ marginTop: 14 }} className="stigg-checkout-summary-captions-next-billing">
      <WithSkeleton isLoading={isFetchingSubscriptionPreview} width="100%">
        {text}
      </WithSkeleton>
    </Typography>
  );
};

export function CheckoutCaptions(props: CheckoutCaptionProps) {
  return (
    <Box className="stigg-checkout-summary-captions">
      <RemainingCreditsCaption {...props} />

      <ScheduledUpdatesCaption {...props} />

      <NextBillingCaption {...props} />
    </Box>
  );
}
