import {
  BillingPeriod,
  SubscriptionStatus,
  Paywall,
  Plan,
  Subscription,
  SubscriptionScheduleType,
} from '@stigg/js-client-sdk';
import isNil from 'lodash/isNil';
import sortBy from 'lodash/sortBy';
import { CurrentSubscriptionOverride, CurrentSubscriptionOverrideFn, PaywallPlan } from '../types';
import { calculateTrialDaysLeft } from './calculateTrialDaysLeft';
import { BillingPeriodChangeVariables, DeepPartial, DowngradeChangeVariables } from '../../../types';
import { StiggTheme } from '../../../theme/types';
import { mapPaywallConfiguration } from '../../common/mapExternalTheme';

function getCustomerSubscriptionDetails(activeSubscriptions?: Subscription[] | null) {
  let isCustomerOnTrial = true;
  let currentSubscription: Subscription | null =
    activeSubscriptions?.find((s) => s.status === SubscriptionStatus.InTrial) || null;
  let currentPlan = currentSubscription?.plan;
  const trialDaysLeft = currentSubscription?.trialEndDate
    ? calculateTrialDaysLeft(currentSubscription.trialEndDate)
    : undefined;
  if (!currentPlan) {
    isCustomerOnTrial = false;
    currentSubscription = activeSubscriptions?.find((s) => s.status === SubscriptionStatus.Active) || null;
    currentPlan = currentSubscription?.plan;
  }

  return {
    currentSubscription,
    currentPlan,
    isCustomerOnTrial,
    trialDaysLeft,
  };
}

type PaywallData = {
  currentPlan?: Plan;
  currentSubscription: Subscription | null;
  currentSubscriptionOverride: CurrentSubscriptionOverride | null | undefined;
  isCustomerOnTrial: boolean;
  plans: PaywallPlan[];
  paywallConfiguration?: DeepPartial<StiggTheme>;
};

export function mapPaywallData(
  paywall: Paywall | null,
  showOnlyEligiblePlans?: boolean,
  currentSubscriptionOverrideFn?: CurrentSubscriptionOverrideFn,
): PaywallData {
  const { plans, currency, configuration, customer, activeSubscriptions, paywallCalculatedPricePoints } = paywall || {};
  const { currentSubscription, currentPlan, isCustomerOnTrial, trialDaysLeft } =
    getCustomerSubscriptionDetails(activeSubscriptions);

  const currentSubscriptionOverride = currentSubscriptionOverrideFn?.({ currentSubscription });

  const scheduledUpdates = currentSubscription?.scheduledUpdates || [];
  const currentCustomerPlanBillingPeriod = currentSubscription?.price?.billingPeriod;
  const downgradeSchedule = scheduledUpdates.find(
    ({ subscriptionScheduleType }) => subscriptionScheduleType === SubscriptionScheduleType.Downgrade,
  );
  const billingChangedSchedule = scheduledUpdates.find(
    ({ subscriptionScheduleType }) => subscriptionScheduleType === SubscriptionScheduleType.BillingPeriod,
  );

  let paywallPlans: PaywallPlan[] = sortBy(plans, (plan) => plan.order).map((plan) => {
    const eligibleForProductTrial = customer?.eligibleForTrial?.find(
      (productTrial) => productTrial.productId === plan.product.id,
    );
    const isCurrentCustomerPlan = currentSubscriptionOverride?.planId
      ? currentSubscriptionOverride?.planId === plan.id
      : plan.id === currentPlan?.id;

    const isNextPlan = (currentBillingPeriod: BillingPeriod) => {
      const downgradeVariables = downgradeSchedule?.scheduleVariables as DowngradeChangeVariables;
      const billingChangedVariables = billingChangedSchedule?.scheduleVariables as BillingPeriodChangeVariables;
      const isPlanHasBillingPeriodPrice = plan.pricePoints.some(
        (price) => price.billingPeriod === currentBillingPeriod,
      );

      return downgradeSchedule
        ? downgradeSchedule.targetPackage?.refId === plan.id &&
            (!isPlanHasBillingPeriodPrice || downgradeVariables.billingPeriod === currentBillingPeriod)
        : billingChangedSchedule && isCurrentCustomerPlan
        ? billingChangedVariables.billingPeriod === currentBillingPeriod
        : false;
    };

    return {
      ...plan,
      paywallCalculatedPricePoints: paywallCalculatedPricePoints?.filter((pricePoint) => pricePoint.planId === plan.id),
      isTriable: !isNil(plan.defaultTrialConfig) && (!eligibleForProductTrial || eligibleForProductTrial.eligible),
      isCurrentCustomerPlan,
      currentCustomerPlanBillingPeriod,
      isUpcomingPlan: false,
      trialDaysLeft,
      isNextPlan,
      paywallCurrency: currency,
      scheduledUpdate: downgradeSchedule || billingChangedSchedule,
    };
  });
  if (plans && currentPlan) {
    const currentPlanOrder = currentPlan && plans?.find((x) => x.id === currentPlan?.id)?.order;
    if (!isNil(currentPlanOrder)) {
      paywallPlans = paywallPlans.map<PaywallPlan>((plan) => ({
        ...plan,
        isLowerThanCurrentPlan: currentPlanOrder > plan.order,
      }));
      if (showOnlyEligiblePlans) {
        paywallPlans = paywallPlans.filter((plan) => plan.order >= currentPlanOrder);
      }
    }
  }
  const paywallConfiguration = configuration ? mapPaywallConfiguration(configuration) : undefined;

  return {
    currentPlan,
    currentSubscription,
    currentSubscriptionOverride,
    isCustomerOnTrial,
    plans: paywallPlans,
    paywallConfiguration,
  };
}
