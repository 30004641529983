import { produce } from 'immer';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { BillableFeature, BillingPeriod, GetCheckoutStateResults } from '@stigg/js-client-sdk';
import { CustomizedTheme, SdkThemeProvider, useStiggTheme } from '../../theme/Theme';
import { DeepPartial } from '../../types';
import { mapCheckoutConfiguration, mapTypography } from '../common/mapExternalTheme';
import {
  AddonsStepState,
  getAddonsStepInitialState,
  getPaymentStepInitialState,
  getPlanStepInitialState,
  getProgressBarInitialState,
  PaymentStepState,
  PlanStepState,
  ProgressBarState,
  useLoadCheckout,
  WidgetState,
} from './hooks';
import { CheckoutLocalization, getResolvedCheckoutLocalize } from './configurations/textOverrides';
import { CheckoutTheme, getResolvedCheckoutTheme } from './configurations/theme';
import { StiggTheme } from '../../theme/types';
import { BillingInformation, MockCheckoutStateCallback } from './types';
import { defaultCheckoutTypography } from './configurations/typography';
import { CheckoutSteps } from './configurations/steps';

export interface CheckoutContextState {
  checkout?: GetCheckoutStateResults | null;
  checkoutLocalization: CheckoutLocalization;
  stiggTheme: StiggTheme;
  theme: CheckoutTheme;
  resourceId?: string;
  promotionCode?: string;
  progressBar: ProgressBarState;
  planStep: PlanStepState;
  addonsStep: AddonsStepState;
  paymentStep: PaymentStepState;
  widgetState: WidgetState;
  isWidgetWatermarkEnabled: boolean;
}

export const CheckoutContext = React.createContext<
  [CheckoutContextState, (updater: (state: CheckoutContextState) => void) => void] | null
>(null);

CheckoutContext.displayName = 'CheckoutContext';

export const useCheckoutContext = () => {
  const ctx = useContext(CheckoutContext);
  if (!ctx) {
    throw new Error(
      'Could not find Checkout context; You need to wrap your checkout components in an <CheckoutProvider> component.',
    );
  }
  return ctx;
};

const CheckoutContextProvider: React.FC<{ children: React.ReactNode; initialState: CheckoutContextState }> = ({
  children,
  initialState,
}) => {
  const [state, innerSetState] = useState(initialState);

  useEffect(() => {
    innerSetState(initialState);
  }, [initialState]);

  const setState = useCallback(
    (updater: (state: CheckoutContextState) => void) => innerSetState((old) => produce(old, (draft) => updater(draft))),
    [innerSetState],
  );

  const [contextValue, setContextValue] = useMemo(() => [state, setState], [setState, state]);

  return <CheckoutContext.Provider value={[contextValue, setContextValue]}>{children}</CheckoutContext.Provider>;
};

export type CheckoutProviderProps = {
  textOverrides?: DeepPartial<CheckoutLocalization>;
  theme?: DeepPartial<CheckoutTheme>;
  resourceId?: string;
  planId: string;
  preferredBillingPeriod?: BillingPeriod;
  billingCountryCode?: string;
  billableFeatures?: BillableFeature[];
  billingInformation?: BillingInformation;
  onMockCheckoutState?: MockCheckoutStateCallback;
  skipCheckoutSteps?: CheckoutSteps[];
};

export function CheckoutProvider({
  children,
  textOverrides,
  theme,
  preferredBillingPeriod,
  billableFeatures,
  resourceId,
  planId,
  billingCountryCode,
  billingInformation,
  onMockCheckoutState,
  skipCheckoutSteps,
}: {
  children: React.ReactNode;
} & CheckoutProviderProps) {
  const { checkout, isLoading, isWidgetWatermarkEnabled } = useLoadCheckout({
    resourceId,
    planId,
    billingCountryCode,
    onMockCheckoutState,
  });
  const configuration: CustomizedTheme = checkout?.configuration
    ? mapCheckoutConfiguration(checkout.configuration)
    : { typography: mapTypography(defaultCheckoutTypography) };
  const globalTheme: StiggTheme = useStiggTheme(configuration);

  const initialState = useMemo(() => {
    const checkoutTheme = getResolvedCheckoutTheme(globalTheme, theme, checkout?.configuration);
    const checkoutLocalization = getResolvedCheckoutLocalize(textOverrides);
    const planStep = getPlanStepInitialState({
      preferredBillingPeriod,
      plan: checkout?.plan,
      activeSubscription: checkout?.activeSubscription,
      billingCountryCode,
      preconfiguredBillableFeatures: billableFeatures ?? [],
    });
    const addonsStep = getAddonsStepInitialState({
      plan: checkout?.plan,
      billingPeriod: planStep.billingPeriod,
      activeSubscription: checkout?.activeSubscription,
    });
    const paymentStep = getPaymentStepInitialState({
      customer: checkout?.customer,
      taxPercentage: billingInformation?.taxDetails?.taxPercentage,
    });
    const progressBar = getProgressBarInitialState({
      isLoading,
      skipCheckoutSteps: skipCheckoutSteps ?? [],
      plan: checkout?.plan,
      availableAddons: addonsStep.availableAddons,
      availableCharges: planStep.billableFeatures,
      preferredBillingPeriod,
      preconfiguredBillableFeatures: billableFeatures ?? [],
    });

    const initialState: CheckoutContextState = {
      checkout,
      checkoutLocalization,
      stiggTheme: globalTheme,
      theme: checkoutTheme,
      progressBar,
      planStep,
      addonsStep,
      paymentStep,
      resourceId: checkout?.resource?.id,
      widgetState: { readOnly: false, isValid: true, isLoadingCheckoutData: isLoading },
      isWidgetWatermarkEnabled,
    };

    return initialState;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    preferredBillingPeriod,
    billingCountryCode,
    checkout,
    isLoading,
    skipCheckoutSteps,
    billingInformation?.taxDetails?.taxPercentage,
  ]);

  return (
    <SdkThemeProvider key={checkout?.plan.id} componentTheme={configuration}>
      <CheckoutContextProvider initialState={initialState}>{children}</CheckoutContextProvider>
    </SdkThemeProvider>
  );
}
