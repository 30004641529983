import React, { useState } from 'react';
import { BillableFeature, BillingPeriod, Customer, PricingType, Subscription } from '@stigg/js-client-sdk';
import styled from '@emotion/styled/macro';
import classNames from 'classnames';
import Grid from '@mui/material/Grid';
import { PlanEntitlements } from './PlanEntitlements';
import { PlanOfferingButton } from './PlanOfferingButton';
import { CurrentSubscriptionOverride, PaywallPlan, SelectDefaultTierIndexFn, SubscribeIntentionType } from './types';
import { PaywallLocalization } from './paywallTextOverrides';
import { flexLayoutMapper } from '../../theme/getResolvedTheme';
import { Typography } from '../common/Typography';
import MiniSchedule from '../../assets/mini-schedule.svg';
import { PlanPrice } from './PlanPrice';
import { getTiersPerUnitQuantities } from '../utils/priceTierUtils';

const PlanOfferingButtonHeight = '66px';

const PlanOfferingContainer = styled.div<{ $isHighlighted: boolean; $isCurrentPlan: boolean }>`
  position: relative;
  background-color: ${({ theme, $isCurrentPlan }) =>
    $isCurrentPlan ? theme.stigg.palette.backgroundHighlight : theme.stigg.palette.backgroundPaper};
  border: ${({ theme, $isHighlighted }) =>
    $isHighlighted ? `3px solid ${theme.stigg.palette.primary}` : `1px solid ${theme.stigg.palette.outlinedBorder}`};
  border-radius: 10px;
  padding: ${({ theme }) => theme.stigg.layout.planPadding};
  display: flex;
  min-width: ${({ theme }) => theme.stigg.layout.planMinWidth};
  max-width: ${({ theme }) => theme.stigg.layout.planMaxWidth};
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
`;

const PlanHeader = styled(Typography)`
  padding-bottom: 8px;
`;

const Divider = styled.div`
  height: 2px;
  width: 100%;
  background-color: ${({ theme }) => theme.stigg.palette.outlinedBorder};
  margin-bottom: 40px;
`;

const PlanDescription = styled(Typography)`
  text-align: ${({ theme }) => theme.stigg.layout.descriptionAlignment};
  min-height: ${({ theme }) => theme.stigg.layout.descriptionMinHeight};
`;

const HighlightBadge = styled.div`
  background-color: ${({ theme }) => theme.stigg.palette.primary};
  border-radius: 40px;
  position: absolute;
  top: -12px;
`;

const HighlightText = styled(Typography)`
  margin: 0;
  padding: 2px 8px;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${({ theme }) => flexLayoutMapper(theme.stigg.layout.headerAlignment)};
`;

type PlanOfferingProps = {
  withUnitPriceRow: boolean;
  withTiersRow: boolean;
  withTrialLeftRow: boolean;
  customer: Customer | null;
  plan: PaywallPlan;
  billingPeriod: BillingPeriod;
  currentSubscription: Subscription | null;
  currentSubscriptionOverride?: CurrentSubscriptionOverride | null;
  isHighlighted: boolean;
  shouldShowDescriptionSection: boolean;
  hasAnnuallyPrice: boolean;
  hasMonthlyPrice: boolean;
  isCustomerOnTrial: boolean;
  onPlanSelected: (intentionType: SubscribeIntentionType, billableFeatures: BillableFeature[]) => void | Promise<void>;
  paywallLocale: PaywallLocalization;
  locale: string;
  withStartingAtRow: boolean;
  isCustomerInCustomPlan: boolean;
  selectDefaultTierIndex?: SelectDefaultTierIndexFn;
};

const NextPlanTagContainer = styled.div`
  position: absolute;
  top: 0;
  background-color: ${({ theme }) => theme.stigg.palette.outlinedBorder};
  border-radius: 0px 0px 10px 10px;
  padding: 3px 12px;
`;

const StyledMiniSchedule = styled(MiniSchedule)<{ $iconsColor?: string }>`
  display: flex;
  height: 100%;
  margin-right: 4px;

  path {
    fill: ${({ theme }) => theme.stigg.palette.text.primary};
  }
`;

function UpcomingChangeTag({ text = 'Next', iconsColor }: { text?: string; iconsColor?: string }) {
  return (
    <NextPlanTagContainer>
      <Grid display="flex" alignItems="center" gap={1}>
        <StyledMiniSchedule $iconsColor={iconsColor} />
        <Typography color="primary" variant="body1">
          {text}
        </Typography>
      </Grid>
    </NextPlanTagContainer>
  );
}

export function PlanOffering({
  withUnitPriceRow,
  withTiersRow,
  withTrialLeftRow,
  customer,
  plan,
  billingPeriod,
  isHighlighted,
  currentSubscription,
  currentSubscriptionOverride,
  shouldShowDescriptionSection,
  hasMonthlyPrice,
  hasAnnuallyPrice,
  isCustomerOnTrial,
  onPlanSelected,
  paywallLocale,
  locale,
  withStartingAtRow,
  isCustomerInCustomPlan,
  selectDefaultTierIndex,
}: PlanOfferingProps) {
  const isNextPlan = plan.isNextPlan && plan.isNextPlan(billingPeriod);
  const planPrices = plan.pricePoints.filter((pricePoint) => pricePoint.billingPeriod === billingPeriod);
  const paywallCalculatedPrice = plan.paywallCalculatedPricePoints?.find(
    (pricePoint) => pricePoint.billingPeriod === billingPeriod,
  );
  const showStartingAt = planPrices.length > 1 && !!paywallCalculatedPrice?.additionalChargesMayApply;
  const showCTAButton = !isCustomerInCustomPlan || plan.pricingType === PricingType.Custom;

  let planBadge = null;
  if (isNextPlan) {
    planBadge = <UpcomingChangeTag text="Next plan" />;
  } else if (isHighlighted && paywallLocale.highlightChip) {
    planBadge = (
      <HighlightBadge className="stigg-highlight-badge">
        <HighlightText className="stigg-highlight-badge-text" color="white" bold>
          {paywallLocale.highlightChip}
        </HighlightText>
      </HighlightBadge>
    );
  }

  const [perUnitQuantityByFeature, setPerUnitQuantityByFeature] = useState<Record<string, number>>(
    getTiersPerUnitQuantities({
      plan,
      billingPeriod,
      currentSubscription,
      currentSubscriptionOverride,
      selectDefaultTierIndex,
    }),
  );

  const onPlanButtonClick = (intentionType: SubscribeIntentionType) => {
    const perUnitBillableFeatures: BillableFeature[] = Object.keys(perUnitQuantityByFeature).map((featureId) => ({
      featureId,
      quantity: perUnitQuantityByFeature[featureId],
    }));

    return onPlanSelected(intentionType, perUnitBillableFeatures);
  };

  return (
    <PlanOfferingContainer
      className={classNames(`stigg-${plan.id}`, 'stigg-plan-offering-container', {
        'stigg-current-plan': plan.isCurrentCustomerPlan,
      })}
      $isHighlighted={isHighlighted}
      $isCurrentPlan={plan.isCurrentCustomerPlan}>
      {planBadge}

      <HeaderWrapper className="stigg-header-wrapper">
        <PlanHeader className="stigg-plan-header" variant="h3">
          {plan.displayName}
        </PlanHeader>

        {shouldShowDescriptionSection && (
          <PlanDescription className="stigg-plan-description" variant="h6" color="secondary">
            {plan.description}
          </PlanDescription>
        )}

        <PlanPrice
          showStartingAt={showStartingAt}
          withUnitPriceRow={withUnitPriceRow}
          withStartingAtRow={withStartingAtRow}
          withTiersRow={withTiersRow}
          plan={plan}
          billingPeriod={billingPeriod}
          paywallLocale={paywallLocale}
          locale={locale}
          hasAnnuallyPrice={hasAnnuallyPrice}
          hasMonthlyPrice={hasMonthlyPrice}
          setPerUnitQuantityByFeature={setPerUnitQuantityByFeature}
          perUnitQuantityByFeature={perUnitQuantityByFeature}
        />

        {showCTAButton ? (
          <PlanOfferingButton
            isNextPlan={isNextPlan}
            customer={customer}
            plan={plan}
            currentSubscription={currentSubscription}
            currentSubscriptionOverride={currentSubscriptionOverride}
            billingPeriod={billingPeriod}
            isCustomerOnTrial={isCustomerOnTrial}
            onPlanSelected={onPlanButtonClick}
            paywallLocale={paywallLocale}
            withTrialLeftRow={withTrialLeftRow}
            perUnitQuantityByFeature={perUnitQuantityByFeature}
          />
        ) : (
          <div style={{ height: PlanOfferingButtonHeight }} />
        )}

        <Divider className="stigg-plan-header-divider" />
      </HeaderWrapper>

      <PlanEntitlements plan={plan} billingPeriod={billingPeriod} paywallLocale={paywallLocale} />
    </PlanOfferingContainer>
  );
}
