import { GetCheckoutStateResults } from '@stigg/js-client-sdk';
import { useEffect, useState } from 'react';
import logger from '../../../services/logger';
import { MockCheckoutStateCallback } from '../types';
import { useStiggContext } from '../../../hooks/useStiggContext';

type UseLoadCheckoutProps = {
  planId: string;
  resourceId?: string;
  billingCountryCode?: string;
  onMockCheckoutState?: MockCheckoutStateCallback;
};

export function useLoadCheckout({ planId, resourceId, billingCountryCode, onMockCheckoutState }: UseLoadCheckoutProps) {
  const { stigg } = useStiggContext();
  const [isLoading, setIsLoading] = useState(true);
  const [checkout, setCheckout] = useState<GetCheckoutStateResults | null>();

  useEffect(() => {
    const loadCheckout = async () => {
      try {
        const checkout = await stigg.getCheckoutState({ planId, resourceId, billingCountryCode });
        setCheckout(checkout);
      } catch (err) {
        logger.error(`Failed to load checkout ${(err as any)?.message}`, err as any);
      } finally {
        setIsLoading(false);
      }
    };

    if (onMockCheckoutState) {
      setIsLoading(false);
      setCheckout(onMockCheckoutState({ planId, resourceId, billingCountryCode }));
      return;
    }

    if (stigg.isCustomerLoaded) {
      setIsLoading(true);
      void loadCheckout();
    }
  }, [stigg, stigg.isCustomerLoaded, resourceId, planId, billingCountryCode, onMockCheckoutState]);

  return {
    checkout,
    isLoading,
    isWidgetWatermarkEnabled: stigg.isWidgetWatermarkEnabled,
  };
}
