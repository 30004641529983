import React from 'react';
import { BillingPeriod } from '@stigg/js-client-sdk';
import { Paywall } from './Paywall';
import { useLoadPaywallData } from './hooks/useLoadPaywallData';
import {
  ShouldHidePlanFn,
  OnPlanSelectedCallbackFn,
  SelectDefaultTierIndexFn,
  CurrentSubscriptionOverrideFn,
} from './types';
import { getResolvedPaywallLocalize, PaywallLocalization } from './paywallTextOverrides';
import { DeepPartial } from '../../types';
import { PaywallLoader } from './PaywallLoader';
import { SdkThemeProvider } from '../../theme/Theme';
import {
  CustomerPortalContext,
  useCheckContextExists,
  useCustomerPortalContext,
} from '../customerPortal/CustomerPortalProvider';

export type PaywallContainerProps = {
  productId?: string;
  resourceId?: string;
  highlightedPlanId?: string;
  showOnlyEligiblePlans?: boolean;
  onPlanSelected: OnPlanSelectedCallbackFn;
  preferredBillingPeriod?: BillingPeriod;
  onBillingPeriodChange?: (billingPeriod: BillingPeriod) => void;
  textOverrides?: DeepPartial<PaywallLocalization>;
  billingCountryCode?: string;
  shouldHidePlan?: ShouldHidePlanFn;
  selectDefaultTierIndex?: SelectDefaultTierIndexFn;
  currentSubscriptionOverride?: CurrentSubscriptionOverrideFn;
};

export const PaywallContainer = ({
  productId,
  resourceId,
  highlightedPlanId,
  showOnlyEligiblePlans,
  textOverrides,
  onPlanSelected,
  preferredBillingPeriod,
  onBillingPeriodChange,
  billingCountryCode,
  shouldHidePlan,
  selectDefaultTierIndex,
  currentSubscriptionOverride: currentSubscriptionOverrideFn,
}: PaywallContainerProps) => {
  const hasCustomerPortalContext = useCheckContextExists(CustomerPortalContext);
  let isCustomerPortalLoading = false;
  if (hasCustomerPortalContext) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { isLoading, resourceId: customerPortalResourceId } = useCustomerPortalContext();
    isCustomerPortalLoading = isLoading;
    resourceId = customerPortalResourceId;
  }
  const {
    plans,
    customer,
    currentSubscription,
    currentSubscriptionOverride,
    isCustomerOnTrial,
    isLoading,
    selectedBillingPeriod,
    setSelectedBillingPeriod,
    availableBillingPeriods,
    locale,
    configuration,
  } = useLoadPaywallData({
    productId,
    resourceId,
    showOnlyEligiblePlans,
    billingCountryCode,
    preferredBillingPeriod,
    currentSubscriptionOverrideFn,
  });
  const paywallLocale = getResolvedPaywallLocalize(textOverrides);
  const handlePeriodChange = (billingPeriod: BillingPeriod) => {
    setSelectedBillingPeriod(billingPeriod);
    if (onBillingPeriodChange) {
      onBillingPeriodChange(billingPeriod);
    }
  };

  const component =
    !plans || isLoading || isCustomerPortalLoading ? (
      <PaywallLoader />
    ) : (
      <Paywall
        plans={plans}
        customer={customer}
        currentSubscription={currentSubscription}
        currentSubscriptionOverride={currentSubscriptionOverride}
        selectedBillingPeriod={selectedBillingPeriod}
        onBillingPeriodChanged={handlePeriodChange}
        availableBillingPeriods={availableBillingPeriods}
        highlightedPlanId={highlightedPlanId}
        isCustomerOnTrial={isCustomerOnTrial}
        onPlanSelected={onPlanSelected}
        paywallLocale={paywallLocale}
        locale={locale}
        shouldHidePlan={shouldHidePlan}
        selectDefaultTierIndex={selectDefaultTierIndex}
      />
    );

  // When rendering the paywall in the customer portal context we don't want to apply paywall theme
  if (hasCustomerPortalContext) {
    return component;
  }
  return <SdkThemeProvider componentTheme={configuration}>{component}</SdkThemeProvider>;
};
